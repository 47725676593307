import React, { Component } from "react";
import "./Logo.css";

import logo from "./images/itspire-logo.svg";

class Logo extends Component {
    render() {
        return (
            <div className="component">
                <img className="logo" src={logo} alt="" />
            </div>
        );
    }
}

export default Logo;
